<template>
  <Container space-before space-after>
    <hr class="divider" aria-hidden="true" />
  </Container>
</template>

<script>
  import Container from "~/components/Container"
  export default {
    components: { Container },
  }
</script>

<style lang="scss" scoped>

  .divider {
    border-width: 1px;
    border-style: solid;
    border-color: $gray-200;
  }
</style>
